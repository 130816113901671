import { graphql } from "gatsby"
import React from "react"
import {
  privacyVerklaring,
  download,
  homepagelink,
} from "./privacyverklaring.module.css"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

/* Components */
import Seo from "../components/seo/seo"
import Layout from "../components/layout"
import Footer from "../components/footer/footer"

/* Components */
import StickyNav from "../components/sticky-nav/sticky-nav"



import {
  th,
  td,
  tableWrapper,
  table as tableClass,
  thead,
  tr,
} from "../components/rich-text-content/table/table.module.css"




const Privacyverklaring = ({ data }) => {
  const navigationDesks = data.navigationDesks.nodes;
  return (
    <Layout>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.mkbservicedesk.nl/privacy-verklaring/"
        />
      </Helmet>
      <header className="header">
        <StickyNav desks={navigationDesks} />
      </header>
      <main className="main">
        <div className="container">
          <Seo title="Privacyverklaring website MKB Servicedesk" />
          <div className={privacyVerklaring}>
<h1>Privacy Statement Van Spaendonck MKB Servicedesk</h1>
<p>Van Spaendonck MKB Servicedesk, onderdeel van Van Spaendonck Groep, ondersteunt ondernemers in het MKB met kennis en advies.</p>
<p>Om deze diensten te verlenen verwerkt Van Spaendonck MKB Servicedesk gegevens over ondernemingen en personen. Bescherming van de privacy is hierbij van essentieel belang: vertrouwen in dienstverlening is cruciaal. Daarom behandelt Van Spaendonck MKB Servicedesk (persoons)gegevens met de grootst mogelijke zorgvuldigheid, waarbij de eisen die voortvloeien uit onder andere de Algemene Verordening Gegevensbescherming in acht worden genomen.</p>
<h2>Welke persoonsgegevens verwerkt Van Spaendonck MKB Servicedesk?</h2>
<p>Van Spaendonck MKB Servicedesk verwerkt de volgende persoonsgegevens:</p>
<h3>Persoonsgegevens die Van Spaendonck MKB Servicedesk verwerkt wanneer je een (potentiële) klant van ons bent.</h3>
<p>Dit betreffen persoonsgegevens die nodig zijn voor het aanbieden of leveren van onze diensten, zoals: voor- en achternaam, geslacht, e-mailadres, telefoonnummer, bedrijfsnaam van de organisatie waar je werkzaam bent, jouw functie, declaratieadres, en alle andere informatie die je, of jouw werkgever, in het kader van de dienstverlening aan ons heeft verstrekt. Wij houden daarnaast bij welke diensten je afneemt en hebt afgenomen en wanneer dit is geweest. Van Spaendonck MKB Servicedesk bewaart deze gegevens gedurende de periode dat een zakelijke of contractuele relatie aanwezig is.</p>
<h3>Persoonsgegevens die je aan Van Spaendonck MKB Servicedesk verstrekt in het kader van een sollicitatie</h3>
<p>Wanneer je reageert op één van onze vacatures of indien je een open sollicitatie hebt gestuurd, dan verwerken wij de volgende persoonsgegevens: NAW-gegevens, e-mailadres, telefoonnummer, CV, motivatiebrief, salarisindicatie, referentie en overige informatie die je aan ons stuurt in het kader van de sollicitatie. Deze persoonsgegevens worden één maand bewaard. Wanneer je daarvoor toestemming geeft, bewaren wij de persoonsgegevens ten behoeve van de sollicitatie maximaal 1 jaar.</p>
<h3>Persoonsgegevens die je aan Van Spaendonck MKB Servicedesk verstrekt voor het versturen van nieuwsbrieven</h3>
<p>Wanneer je je aanmeldt voor onze nieuwsbrief verwerken wij de volgende persoonsgegevens:</p>
<ol className={"list"}>
  <li>Voor- en achternaam</li>
  <li>E-mailadres</li>
</ol>
<p>Deze persoonsgegevens worden verwijderd zodra de betrokkene zich uitschrijft voor de betreffende nieuwsbrief.</p>
<h3>Persoonsgegevens die Van Spaendonck MKB Servicedesk ontvangt door het gebruik van onze website</h3>
<p>Indien je gebruik maakt van onze website, worden de volgende persoonsgegevens verwerkt:
IP-adres, Netwerk interactie en lees- en klikgedrag. Dit gebeurt door het gebruik van Cookies. Deze cookies blijven actief zolang de bezoeker van de website dit toestaat.</p>
<h3>Persoonsgegevens die Van Spaendonck MKB Servicedesk registreert ten aanzien van de bezoekersregistratie van het ondernemingshuis</h3>
<p>Bezoekers van het kantoorgebouw (ondernemingshuis) van Van Spaendonck MKB Servicedesk worden geregistreerd ten behoeve van de veiligheid van het ondernemingshuis. Hiervoor worden de volgende gegevens geregistreerd: naam, bedrijf en tijdstip van aan- en afmelding. Deze persoonsgegevens worden elke dag opgeschoond. </p>
<h3>Anonimiseren van Persoonsgegevens</h3>
<p>Van Spaendonck MKB Servicedesk kan persoonsgegevens die het verkrijgt bij bovengenoemde verwerkingsactiviteiten anonimiseren. Dit betekent dat de oorspronkelijk verkregen persoonsgegevens zodanig anoniem gemaakt, dat de betrokkene niet meer identificeerbaar is aan de hand van de gegevens. De geanonimiseerde gegevens worden gebruikt voor wetenschappelijke- en of statische doeleinden. </p>
<h2>Voor welke doeleinden verwerkt Van Spaendonck MKB Servicedesk persoonsgegevens?</h2>
<p>Van Spaendonck MKB Servicedesk verwerkt persoonsgegevens voor de volgende doeleinden:</p>
<ol className={"list"}>
  <li>Aanbieden en/of uitvoeren van de dienstverlening(sovereenkomst);</li>
  <li>Aannemen van personeel;</li>
  <li>Aanwezigheidsregistratie bezoekers in verband met veiligheid;</li>
  <li>Beheren van inschrijvingen voor evenementen, trainingen, webinars en seminars;</li>
  <li>Costumer Relationship Management</li>
  <li>Geïnteresseerde op de hoogte houden van de dienstverlening van Van Spaendonck MKB Servicedesk;</li>
  <li>Marketing en Marktonderzoek;</li>
  <li>Om te voldoen aan de wettelijke verplichtingen;</li>
  <li>Optimaliseren van de website;</li>
  <li>Optimaliseren van onze diensten en dienstverlening;</li>
  <li>Voor wetenschappelijk- en/of statistisch onderzoek</li>
</ol>
<h2>Met welke grondslag verwerkt Van Spaendonck MKB Servicedesk mijn gegevens?</h2>
<p>Van Spaendonck MKB Servicedesk verwerkt alleen persoonsgegevens indien hier een wettelijke grondslag voor aanwezig is. Indien je een (potentiële) klant bent, verwerken wij de persoonsgegevens omdat dit noodzakelijk is voor de uitvoering van een overeenkomst. </p>
<p>Daarnaast verwerkt Van Spaendonck MKB Servicedesk persoonsgegevens omdat Van Spaendonck MKB Servicedesk hiervoor een gerechtvaardigd belang heeft. Zo verwerkt Van Spaendonck MKB Servicedesk de aanwezigheidsregistratie in het ondernemingshuis voor de veiligheid van het ondernemingshuis en de bezoekers. Hierbij wegen wij als Van Spaendonck MKB Servicedesk eerst zorgvuldig de belangen af. </p>
<p>Verder verwerkt Van Spaendonck MKB Servicedesk persoonsgegevens om te voldoen aan de wettelijke verplichtingen. Zo kan Van Spaendonck MKB Servicedesk genoodzaakt zijn om een cliëntenonderzoek uit te voeren of kunnen gegevens worden verstrekt voor de uitvoering van de belastingwetgeving. </p>
<p>Indien Van Spaendonck MKB Servicedesk geen beroep kan doen op voorgenoemde grondslagen, vragen wij om toestemming voor de verwerking van je persoonsgegevens. Je hebt het recht om de toestemming altijd in te trekken. </p>
<h2>Met wie delen wij persoonsgegevens?</h2>
<p>Van Spaendonck MKB Servicedesk deelt gegevens met partijen die namens Van Spaendonck MKB Servicedesk bepaalde diensten uitvoeren en als verwerker namens Van Spaendonck MKB Servicedesk persoonsgegevens verwerken. Van Spaendonck MKB Servicedesk maakt bijvoorbeeld gebruik van een externe partij voor het hosten van de website, het versturen van nieuwsbrieven, het opslaan van data en nog meer verschillende diensten. Hieronder een lijst van verwerkers die Van Spaendonck MKB Servicedesk gebruikt bij het verwerken van persoonsgegevens. </p>
<div className={tableWrapper}>
  <table className={tableClass}>

  <thead className={thead}>
    <tr className={tr}>
      <th className={th}>Welke derde partij verwerkt gegevens namens Van Spaendonck MKB Servicedesk? </th>
      <th className={th}>Verwerkingsactiviteit </th>
      <th className={th}>Voor welk doeleinden verwerkt Van Spaendonck MKB Servicedesk deze gegevens? </th>
      <th className={th}>In welke regio vindt deze verwerking plaats? </th>
    </tr>
  </thead>

  <tbody className="tbody">
  <tr className={tr}>
	<td className={td}>ActiveCampain </td>
	<td className={td}>Nieuwsbrieven </td>
	<td className={td}>Marketing &amp; Marktonderzoek </td>
	<td className={td}>Verenigde Staten (SCC afgesloten) </td>
  </tr>
  <tr className={tr}>
	<td className={td}>Cookiebot </td>
	<td className={td}>Cookies </td>
	<td className={td}>Optimaliseren van website, Marketing &amp; Marktonderzoek </td>
	<td className={td}>EER (Ierland) </td>
  </tr>
  <tr className={tr}>
	<td className={td}>Eva Solutions  </td>
	<td className={td}>Bezoekersregistratie </td>
	<td className={td}>Aanwezigheidsregistratie bezoekers in verband met veiligheid; </td>
	<td className={td}>EER (Nederland) </td>
  </tr>
  <tr className={tr}>
	<td className={td}>Exact Netherlands B.V. </td>
	<td className={td}>Facturatie- en administratieproces </td>
	<td className={td}>Uitvoeren van de dienstverlening(sovereenkomst) </td>
	<td className={td}>EER (Ierland &amp; Nederland) </td>
  </tr>
  <tr className={tr}>
	<td className={td}>Google  </td>
	<td className={td}>Google Analytics </td>
	<td className={td}>Optimaliseren van website, Marketing &amp; Marktonderzoek </td>
	<td className={td}>EER (Ierland) </td>
  </tr>
  <tr className={tr}>
	<td className={td}>Hotjar </td>
	<td className={td}>Invullen polls en enquêtes website </td>
	<td className={td}>Marketing &amp; Marktonderzoek </td>
	<td className={td}>EER (Ierland) </td>
  </tr>
  <tr className={tr}>
	<td className={td}>Hubspot </td>
	<td className={td}>Marketing </td>
	<td className={td}>Marketing &amp; Marktonderzoek </td>
	<td className={td}>Verenigde Staten (SCC afgesloten) </td>
  </tr>
  <tr className={tr}>
	<td className={td}>Mailchimp </td>
	<td className={td}>Nieuwbrieven </td>
	<td className={td}>Marketing &amp; Marktonderzoek </td>
	<td className={td}>Verenigde Staten (SCC afgesloten) </td>
  </tr>
  <tr className={tr}>
	<td className={td}>Microsoft </td>
	<td className={td}>Opslag van (klant)data </td>
	<td className={td}>Hosting data </td>
	<td className={td}>EER (Ierland) </td>
  </tr>
  <tr className={tr}>
	<td className={td}>Momentive </td>
	<td className={td}>Enquêtes, polls </td>
	<td className={td}>Marketing &amp; Marktonderzoek </td>
	<td className={td}>Verenigde Staten (SCC afgesloten) </td>
  </tr>
  <tr className={tr}>
	<td className={td}>Momice </td>
	<td className={td}>Inschrijvingen voor events &amp; bijeenkomsten </td>
	<td className={td}>Beheren van inschrijvingen voor evenementen, trainingen, webinars en seminars; </td>
	<td className={td}>EER (Duitsland) </td>
  </tr>
  <tr className={tr}>
	<td className={td}>Outgrow </td>
	<td className={td}>Enquêtes, polls </td>
	<td className={td}>Marketing &amp; Marktonderzoek </td>
	<td className={td}>India </td>
  </tr>
  <tr className={tr}>
	<td className={td}>Stripe </td>
	<td className={td}>Betaling lidmaatschap </td>
	<td className={td}>Betalingen MKB-servicedesk </td>
	<td className={td}>Verenigde Staten </td>
  </tr>
  <tr className={tr}>
	<td className={td}>Tool2Match </td>
	<td className={td}>Het werven en selecteren van personeel  </td>
	<td className={td}>Aannemen van personeel </td>
	<td className={td}>EER (Nederland) </td>
  </tr>
  <tr className={tr}>
	<td className={td}>Typeform </td>
	<td className={td}>Vragenlijsten </td>
	<td className={td}>Marketing &amp; Marktonderzoek </td>
	<td className={td}>Verenigde Staten (SCC afgesloten) </td>
  </tr>
</tbody>
</table>
</div>

<h2>Jouw rechten met betrekking tot persoonsgegevens</h2>
<p>Van Spaendonck MKB Servicedesk vindt jouw privacy belangrijk. Daarom willen wij jou graag wijzen op de verschillende rechten in het kader van de privacywetgeving. Je hebt het recht om jouw persoonsgegevens in te zien, te corrigeren of te laten vernietigen. Daarnaast heb je het recht om de gegeven toestemming in te trekken of bezwaar te maken tegen de verwerking van jouw persoonsgegevens door Van Spaendonck MKB Servicedesk. Tot slot heb je het recht om jouw gegevens over te laten dragen aan ander. Soms kan het zijn dat een wettelijke verplichting zich verzet tegen bovenstaande rechten. In dat geval informeren wij je hierover.</p>

<h2>Hoe kun je jouw rechten uitoefenen?</h2>
<p>Indien je jouw rechten wil inroepen, kun je hiervoor schriftelijk contact opnemen met
   Van Spaendonck MKB Servicedesk via {" "} <a href="mailto:compliance@vanspaendonck.nl">compliance@vanspaendonck.nl</a>
</p>

<h2>Jouw rechten in geval van Van Spaendonck MKB Servicedesk als verwerker</h2>
<p>Van Spaendonck MKB Servicedesk kan in opdracht van een klant persoonsgegevens verwerken. Indien een klant van Van Spaendonck MKB Servicedesk jouw gegevens bij ons heeft ingediend en je wil jouw rechten met betrekking tot persoonsgegevens uitoefenen, neem dan contact op met de betreffende klant. 
Van Spaendonck MKB Servicedesk mag alleen gegevens van een klant inzien op instructie van de betreffende klant. Wij sturen jouw verzoek door naar de klant en ondersteunen hen waar nodig bij dit verzoek.</p>


<h2>Beveiliging van jouw persoonsgegevens</h2>
<p>Binnen Van Spaendonck MKB Servicedesk hebben wij verschillende technische- en organisatorische maatregelen om ervoor te zorgen dat de persoonsgegevens die wij verwerken of gebruiken worden beschermd tegen onopzettelijke of onrechtmatige vernietiging, verlies, wijziging of ongeoorloofde verstrekking of toegang. Deze verschillende maatregelen zorgen voor een passend beveiligingsniveau. </p>
<h2>Vragen</h2>
<p>Heb je een vraag over de verwerking van persoonsgegevens of over de beveiliging van jouw persoonsgegevens? Dan kunnen deze worden gesteld via  {" "} <a href="mailto:compliance@vanspaendonck.nl">compliance@vanspaendonck.nl</a></p>

<h2>Klachten</h2>
<p>Je kunt een klacht indienen over de verwerking van persoonsgegevens door een e-mail te versturen naar  {" "} <a href="mailto:compliance@vanspaendonck.nl">compliance@vanspaendonck.nl</a>. Tot slot heb je het recht om een klacht in te dienen bij de Autoriteit Persoonsgegevens.  </p>

            <Link
              className={download}
              to="https://www.mkbservicedesk.nl/documenten/privacy-verklaring.pdf"
            >
              Privacy Verklaring Downloaden
            </Link>
            <p className={homepagelink}>
              Klik{" "}
              <a href="/" title="MKB Servicedesk Homepage">
                hier
              </a>{" "}
              om naar de homepage te gaan.
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </Layout>
  )
}

export default Privacyverklaring

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    navigationDesks: allContentfulDesk(sort: { fields: sortNavigation }) {
      nodes {
        id
        name
        slug2
        sortNavigation
        ctaNavigation {
          image {
            title
            gatsbyImageData(layout: CONSTRAINED, width: 1180)
          }
          title
          slug
          categories {
            slug2
            desk {
              slug2
            }
          }
        }
        deskcategory {
          id
          name
          slug2
          desk {
            id
            name
            slug2
          }
          article {
            id
            title
            slug2
            updateDate
            categories {
              slug2
              desk {
                slug2
              }
            }
          }
        }
      }
    }
  }
`
